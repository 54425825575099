// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-tsx": () => import("/opt/build/repo/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-clinical-risk-assessments-biologic-product-tsx": () => import("/opt/build/repo/src/pages/clinical-risk-assessments/biologic-product.tsx" /* webpackChunkName: "component---src-pages-clinical-risk-assessments-biologic-product-tsx" */),
  "component---src-pages-clinical-risk-assessments-index-tsx": () => import("/opt/build/repo/src/pages/clinical-risk-assessments/index.tsx" /* webpackChunkName: "component---src-pages-clinical-risk-assessments-index-tsx" */),
  "component---src-pages-clinical-risk-assessments-medical-device-tsx": () => import("/opt/build/repo/src/pages/clinical-risk-assessments/medical-device.tsx" /* webpackChunkName: "component---src-pages-clinical-risk-assessments-medical-device-tsx" */),
  "component---src-pages-clinical-risk-assessments-pharma-product-tsx": () => import("/opt/build/repo/src/pages/clinical-risk-assessments/pharma-product.tsx" /* webpackChunkName: "component---src-pages-clinical-risk-assessments-pharma-product-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("/opt/build/repo/src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("/opt/build/repo/src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-training-tsx": () => import("/opt/build/repo/src/pages/training.tsx" /* webpackChunkName: "component---src-pages-training-tsx" */)
}

